@font-face{
    font-family: 'Source Code Pro';
    font-weight: 200;
    font-style: normal;
    font-stretch: normal;
    src: url('./WOFF2/TTF/SourceCodePro-ExtraLight.ttf.woff2') format('woff2'),
         url('./WOFF/OTF/SourceCodePro-ExtraLight.otf.woff') format('woff'),
         url('./OTF/SourceCodePro-ExtraLight.otf') format('opentype'),
         url('./TTF/SourceCodePro-ExtraLight.ttf') format('truetype');
}

@font-face{
    font-family: 'Source Code Pro';
    font-weight: 200;
    font-style: italic;
    font-stretch: normal;
    src: url('./WOFF2/TTF/SourceCodePro-ExtraLightIt.ttf.woff2') format('woff2'),
         url('./WOFF/OTF/SourceCodePro-ExtraLightIt.otf.woff') format('woff'),
         url('./OTF/SourceCodePro-ExtraLightIt.otf') format('opentype'),
         url('./TTF/SourceCodePro-ExtraLightIt.ttf') format('truetype');
}

@font-face{
    font-family: 'Source Code Pro';
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    src: url('./WOFF2/TTF/SourceCodePro-Light.ttf.woff2') format('woff2'),
         url('./WOFF/OTF/SourceCodePro-Light.otf.woff') format('woff'),
         url('./OTF/SourceCodePro-Light.otf') format('opentype'),
         url('./TTF/SourceCodePro-Light.ttf') format('truetype');
}

@font-face{
    font-family: 'Source Code Pro';
    font-weight: 300;
    font-style: italic;
    font-stretch: normal;
    src: url('./WOFF2/TTF/SourceCodePro-LightIt.ttf.woff2') format('woff2'),
         url('./WOFF/OTF/SourceCodePro-LightIt.otf.woff') format('woff'),
         url('./OTF/SourceCodePro-LightIt.otf') format('opentype'),
         url('./TTF/SourceCodePro-LightIt.ttf') format('truetype');
}

@font-face{
    font-family: 'Source Code Pro';
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    src: url('./WOFF2/TTF/SourceCodePro-Regular.ttf.woff2') format('woff2'),
         url('./WOFF/OTF/SourceCodePro-Regular.otf.woff') format('woff'),
         url('./OTF/SourceCodePro-Regular.otf') format('opentype'),
         url('./TTF/SourceCodePro-Regular.ttf') format('truetype');
}

@font-face{
    font-family: 'Source Code Pro';
    font-weight: 400;
    font-style: italic;
    font-stretch: normal;
    src: url('./WOFF2/TTF/SourceCodePro-It.ttf.woff2') format('woff2'),
         url('./WOFF/OTF/SourceCodePro-It.otf.woff') format('woff'),
         url('./OTF/SourceCodePro-It.otf') format('opentype'),
         url('./TTF/SourceCodePro-It.ttf') format('truetype');
}

@font-face{
    font-family: 'Source Code Pro';
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    src: url('./WOFF2/TTF/SourceCodePro-Medium.ttf.woff2') format('woff2'),
         url('./WOFF/OTF/SourceCodePro-Medium.otf.woff') format('woff'),
         url('./OTF/SourceCodePro-Medium.otf') format('opentype'),
         url('./TTF/SourceCodePro-Medium.ttf') format('truetype');
}

@font-face{
    font-family: 'Source Code Pro';
    font-weight: 500;
    font-style: italic;
    font-stretch: normal;
    src: url('./WOFF2/TTF/SourceCodePro-MediumIt.ttf.woff2') format('woff2'),
         url('./WOFF/OTF/SourceCodePro-MediumIt.otf.woff') format('woff'),
         url('./OTF/SourceCodePro-MediumIt.otf') format('opentype'),
         url('./TTF/SourceCodePro-MediumIt.ttf') format('truetype');
}

@font-face{
    font-family: 'Source Code Pro';
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    src: url('./WOFF2/TTF/SourceCodePro-Semibold.ttf.woff2') format('woff2'),
         url('./WOFF/OTF/SourceCodePro-Semibold.otf.woff') format('woff'),
         url('./OTF/SourceCodePro-Semibold.otf') format('opentype'),
         url('./TTF/SourceCodePro-Semibold.ttf') format('truetype');
}

@font-face{
    font-family: 'Source Code Pro';
    font-weight: 600;
    font-style: italic;
    font-stretch: normal;
    src: url('./WOFF2/TTF/SourceCodePro-SemiboldIt.ttf.woff2') format('woff2'),
         url('./WOFF/OTF/SourceCodePro-SemiboldIt.otf.woff') format('woff'),
         url('./OTF/SourceCodePro-SemiboldIt.otf') format('opentype'),
         url('./TTF/SourceCodePro-SemiboldIt.ttf') format('truetype');
}

@font-face{
    font-family: 'Source Code Pro';
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    src: url('./WOFF2/TTF/SourceCodePro-Bold.ttf.woff2') format('woff2'),
         url('./WOFF/OTF/SourceCodePro-Bold.otf.woff') format('woff'),
         url('./OTF/SourceCodePro-Bold.otf') format('opentype'),
         url('./TTF/SourceCodePro-Bold.ttf') format('truetype');
}

@font-face{
    font-family: 'Source Code Pro';
    font-weight: 700;
    font-style: italic;
    font-stretch: normal;
    src: url('./WOFF2/TTF/SourceCodePro-BoldIt.ttf.woff2') format('woff2'),
         url('./WOFF/OTF/SourceCodePro-BoldIt.otf.woff') format('woff'),
         url('./OTF/SourceCodePro-BoldIt.otf') format('opentype'),
         url('./TTF/SourceCodePro-BoldIt.ttf') format('truetype');
}

@font-face{
    font-family: 'Source Code Pro';
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    src: url('./WOFF2/TTF/SourceCodePro-Black.ttf.woff2') format('woff2'),
         url('./WOFF/OTF/SourceCodePro-Black.otf.woff') format('woff'),
         url('./OTF/SourceCodePro-Black.otf') format('opentype'),
         url('./TTF/SourceCodePro-Black.ttf') format('truetype');
}

@font-face{
    font-family: 'Source Code Pro';
    font-weight: 900;
    font-style: italic;
    font-stretch: normal;
    src: url('./WOFF2/TTF/SourceCodePro-BlackIt.ttf.woff2') format('woff2'),
         url('./WOFF/OTF/SourceCodePro-BlackIt.otf.woff') format('woff'),
         url('./OTF/SourceCodePro-BlackIt.otf') format('opentype'),
         url('./TTF/SourceCodePro-BlackIt.ttf') format('truetype');
}
